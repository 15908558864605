<template>
  <div class="scene4">
    <sceneComs></sceneComs>
  </div>
</template>
<script>
import mitt from "@/lib/bus"
import sceneComs from "../components/three-coms/index.vue";
import bus from "../components/utils/bus"
export default {
  components: {
    sceneComs,
  },
  data() {
    return {
      isOpenAll: false,
      socket: "",
      devMap: {
        openBq: {
          busName: "all",
        },
        toMain1: {
          busName: "last",
        },
        toCBD: {
          busName: "CBD",
        },
        toDC: {
          busName: "虚拟电厂",
        },
        toST: {
          busName: "3060双碳",
        },
        toWEFamil: {
          busName: "云",
        },
        toYQ: {
          busName: "综合智慧园区",
        },
        toZX: {
          busName: "整县推进",
        },
      },
      lastMessage: "",
    };
  },
  mounted() {
    let _this=this
    setTimeout(() => {
      console.log("mitt")
      mitt.on("sod",(data)=>{
      console.log(data);
      _this.wsMessage({data})
    })  
    }, 1000);
  },
  methods: {
    initWebSocket() {
      let wsUrl = process.env.VUE_APP_BASE_WSURL;
      this.socket = new WebSocket(wsUrl);
      this.socket.onmessage = this.wsMessage;
      this.socket.onopen = this.wsOpen;
      this.socket.onclose = this.wsClose;
      this.socket.onerror = this.wsError;
    },
    handleOrder(dev) {
      let { busName } = this.devMap[dev];
      if (busName == "all") {
        this.isOpenAll = !this.isOpenAll;
        bus.emit("three-coms-isShowAllSprite", this.isOpenAll);
        return;
      }
      if (this.lastMessage || busName == "last") {
        bus.emit("isShowWireFrame", this.lastMessage, false);
      }
      bus.emit("isShowWireFrame", busName, true);
      this.lastMessage = busName;
    },
    wsMessage({ data }) {
      try {
        let message = JSON.parse(data);
        if (message.scene.includes("scene4")) {
          let { deactive } = message;
          this.handleOrder(deactive);
        }
      } catch (e) {
        console.log("错误捕获", e);
      }
    },
    wsOpen() {
      console.log("打开socket");
    },
    wsClose(e) {
      console.log("断开连接", e);
      this.initWebSocket();
    },
    wsError(e) {
      console.log("错误", e);
    },
  },
  unmounted(){
    mitt.off("sod")
  }
};
</script>
<style lang="less" scoped>
.scene4 {
  // width: 100%;
  // height: 100%;
  width: 3840px;
  height: 3240px;
  // width: 384px;
  // height: 324px;
  position: absolute;
  overflow: hidden;
}
</style>
